<template>
  <div>
    <v-switch
      v-model="value"
      :true-value="false"
      :false-value="true"
      color="success"
      class="custom-switch"
      small
      :label="
        !value
          ? showLabel
            ? capitalize(unsnoozedLabel)
            : ''
          : reopenTime && showTime
          ? capitalize(snoozedLabel) + ' until ' + reopenTime
          : capitalize(snoozedLabel)
      "
      readonly
      @click.native.stop="toggleSnooze(item)"
    ></v-switch>
    <form-dialog
      v-if="$route.params.id == item.id"
      :visible="$route.params.form == 'Snooze'"
      :title="capitalize(snoozeLabel) + ' ' + type"
      :showFormTitle="false"
      :closeDisabled="isSubmitting"
      :awaitingResponse="isSubmitting"
      :submitDisabled="isSubmitting"
      @close="closeDialog"
      @submit="confirmSnooze"
    >
      <v-row justify="center" class="mt-2">
        <v-col cols="5">
          <v-select
            v-model="snoozeFor"
            :items="snoozeOptions"
            item-text="name"
            item-value="value"
            label="Snooze for a set number of hours"
            outlined
          />
        </v-col>
        <v-col cols="5">
          <v-text-field
            ref="snoozeUntil"
            v-model="snoozeUntil"
            type="time"
            time-format="HH:mm"
            label="Snooze until a set time"
            outlined
          />
        </v-col>
      </v-row>
    </form-dialog>
    <confirmation-dialog
      v-if="$route.params.id == item.id"
      :show="$route.params.form == 'ConfirmUnsnooze'"
      :custom-wording="true"
      :action="unsnoozeActionName"
      color="primary"
      @confirm="confirmUnsnooze"
    />
  </div>
</template>

<script>
import { doc, updateDoc } from "firebase/firestore"
import { mapState, mapActions } from "vuex"
import FormDialog from "./dialogs/FormDialog.vue"
import ConfirmationDialog from "./dialogs/ConfirmationDialog.vue"
import moment from "moment"
import "moment-timezone"

export default {
  name: "nxg-snooze-btn",
  components: {
    FormDialog,
    ConfirmationDialog,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    showTime: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isSubmitting: false,
      snoozeUntil: "",
      snoozeFor: "IND",
      snoozeOptions: [
        { name: "indefinitely", value: "IND" },
        { name: "1 hour", value: "1" },
        { name: "2 hours", value: "2" },
        { name: "4 hours", value: "4" },
        { name: "6 hours", value: "6" },
        { name: "8 hours", value: "8" },
        { name: "10 hours", value: "10" },
        { name: "12 hours", value: "12" },
        { name: "end of today", value: "EOD" },
        { name: "custom", value: "" },
      ],
    }
  },
  computed: {
    ...mapState(["firebaseRefs"]),
    value: {
      get() {
        return this.item.isSnoozed ? true : false
      },
      set(value) {
        this.item.isSnoozed = value
      },
    },
    collection() {
      switch (this.type) {
        case "restaurant":
          return this.firebaseRefs.restaurantsRef
        case "menu item":
          return this.firebaseRefs.menuitemsRef
        case "modifier item":
          return this.firebaseRefs.modifieritemsRef
      }
      return null
    },
    snoozeUntilMS() {
      let newSnooze
      if (this.snoozeFor.length) {
        if (this.snoozeFor == "IND") {
          return null
        }
        if (this.snoozeFor == "EOD") {
          newSnooze = moment().endOf("day")
        } else {
          newSnooze = moment().add(this.snoozeFor, "hours")
        }
      } else {
        newSnooze = moment(this.snoozeUntil, "HH:mm")
        if (newSnooze.isBefore(moment())) {
          newSnooze.add(1, "day")
        }
      }
      return newSnooze.valueOf()
    },
    reopenTime() {
      if (this.value && this.item.snoozeUntil > 0) {
        var momentDate = moment.unix(this.item.snoozeUntil / 1000)
        if (momentDate.isValid()) {
          return momentDate.tz("America/Vancouver").format("h:mm A")
        }
      }
      return ""
    },
    unsnoozeLabel() {
      switch (this.type) {
        case "restaurant":
          return "open"
        default:
          return "unsnooze"
      }
    },
    unsnoozedLabel() {
      switch (this.type) {
        case "restaurant":
          return "open"
        default:
          return "available"
      }
    },
    snoozeLabel() {
      switch (this.type) {
        case "restaurant":
          return "close"
        default:
          return "snooze"
      }
    },
    snoozedLabel() {
      switch (this.type) {
        case "restaurant":
          return "closed"
        default:
          return "snoozed"
      }
    },
    unsnoozeActionName() {
      return "Would you like to " + this.unsnoozeLabel + " this " + this.type
    },
  },
  watch: {
    snoozeUntil() {
      this.snoozeFor = ""
    },
  },
  mounted() {
    if (!this.value) {
      this.value = false
    }
  },
  methods: {
    ...mapActions(["updateRestaurantStatus"]),
    toggleSnooze(item) {
      if (item.isSnoozed) {
        this.unsnooze()
      } else {
        this.snooze()
      }
    },
    snooze() {
      this.$router.push({ params: { form: "Snooze", id: this.item.id } })
    },
    unsnooze() {
      this.$router.push({
        params: { form: "ConfirmUnsnooze", id: this.item.id },
      })
    },
    async confirmUnsnooze() {
      switch (this.type) {
        case "restaurant":
          await this.updateRestaurantStatus({
            id: this.$route.params.id,
            isSnoozed: false,
            snoozeUntil: -1,
          }).then(() => {
            this.value = false
            this.snoozeUntil = -1
          })
          break
        default:
        case "menu item":
        case "modifier item":
          await updateDoc(doc(this.collection, this.$route.params.id), {
            isSnoozed: false,
            snoozeUntil: -1,
          }).then(() => {
            this.value = false
            this.snoozeUntil = -1
          })
          break
      }
      this.$router.push({ params: { form: null, id: null } })
      this.$emit("submit")
    },
    async confirmSnooze() {
      this.isSubmitting = true
      await updateDoc(doc(this.collection, this.$route.params.id), {
        isSnoozed: true,
        snoozeUntil: this.snoozeUntilMS,
      }).then(() => {
        this.value = true
        this.item.snoozeUntil = this.snoozeUntilMS
      })
      switch (this.type) {
        case "restaurant":
          this.$store.dispatch("fetchRestaurants")
          break
        case "menu item":
          break
      }
      this.item.snoozeUntil = this.snoozeUntilMS
      this.$emit("submit")
      this.closeDialog()
      this.isSubmitting = false
    },
    capitalize(s) {
      return s[0].toUpperCase() + s.slice(1)
    },
    closeDialog() {
      this.$router.push({ params: { form: null, id: null } })
    },
  },
}
</script>

<style>
.custom-switch label {
  font-size: 0.85em;
}
</style>
