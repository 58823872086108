<template>
  <v-dialog
    v-model="show"
    :retain-focus="false"
    persistent
    max-width="700"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title>
        <h5 v-if="!customWording">Are you sure you want to {{ action }}?</h5>
        <h5 v-else>{{ action }}?</h5>
      </v-card-title>
      <v-card-actions class="justify-end">
        <v-btn color="secondary" @click="cancel">Cancel</v-btn>
        <v-btn :color="color" @click="$emit('confirm')">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "confirmation-dialog",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    customWording: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: "error",
    },
  },
  methods: {
    cancel() {
      this.$router.push({ params: { form: null, id: null } })
      this.$emit("close")
    },
  },
}
</script>
