var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-switch',{staticClass:"custom-switch",attrs:{"true-value":false,"false-value":true,"color":"success","small":"","label":!_vm.value
        ? _vm.showLabel
          ? _vm.capitalize(_vm.unsnoozedLabel)
          : ''
        : _vm.reopenTime && _vm.showTime
        ? _vm.capitalize(_vm.snoozedLabel) + ' until ' + _vm.reopenTime
        : _vm.capitalize(_vm.snoozedLabel),"readonly":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.toggleSnooze(_vm.item)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),(_vm.$route.params.id == _vm.item.id)?_c('form-dialog',{attrs:{"visible":_vm.$route.params.form == 'Snooze',"title":_vm.capitalize(_vm.snoozeLabel) + ' ' + _vm.type,"showFormTitle":false,"closeDisabled":_vm.isSubmitting,"awaitingResponse":_vm.isSubmitting,"submitDisabled":_vm.isSubmitting},on:{"close":_vm.closeDialog,"submit":_vm.confirmSnooze}},[_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{attrs:{"items":_vm.snoozeOptions,"item-text":"name","item-value":"value","label":"Snooze for a set number of hours","outlined":""},model:{value:(_vm.snoozeFor),callback:function ($$v) {_vm.snoozeFor=$$v},expression:"snoozeFor"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{ref:"snoozeUntil",attrs:{"type":"time","time-format":"HH:mm","label":"Snooze until a set time","outlined":""},model:{value:(_vm.snoozeUntil),callback:function ($$v) {_vm.snoozeUntil=$$v},expression:"snoozeUntil"}})],1)],1)],1):_vm._e(),(_vm.$route.params.id == _vm.item.id)?_c('confirmation-dialog',{attrs:{"show":_vm.$route.params.form == 'ConfirmUnsnooze',"custom-wording":true,"action":_vm.unsnoozeActionName,"color":"primary"},on:{"confirm":_vm.confirmUnsnooze}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }